// 静态路由
export const staticRouter = [
    {
        path:'/',
        component:() => import('../views/Index.vue'),
        meta: { title: ''}
    },
    {
        path:'/:id',
        component:() => import('../views/Index.vue'),
        meta: { title: ''}
    },
    {
        path:'/coupon/:id',
        component:() => import('../views/coupon/Index.vue'),
        meta: { title: 'Coupon'}
    },
    {
        path:'/takeaway/:id',
        component:() => import('../views/pages/IndexView.vue'),
        meta: { title: ''}
    },
    {
        path:'/takeaway/paymentSuccess/:id',
        component:() => import('../views/results/PaymentSuccess.vue'),
        meta: { title: ''}
    },
    {
        path:'/takeaway/paymentFailed/:id',
        component:() => import('../views/results/PaymentFailed.vue'),
        meta: { title: ''}
    },
    {
        path:'/takeaway/cartList/:id',
        component:() => import('../views/pages/CartListView.vue'),
        meta: { title: 'Delivery and time'}
    },
    {
        path:'/takeaway/buyerInfo/:id',
        component:() => import('../views/pages/BuyerInfoView.vue'),
        meta: { title: 'Delivery and time'}
    },
    {
        path:'/booking/:tempId/:id',
        component:() => import('../views/pages/BookingTable.vue'),
        meta: { title: ''}
    },
    {
        path:'/info/:tempId/:customId/:id',
        component:() => import('../views/pages/CustomView.vue'),
        meta: { title: ''}
    },
    {
        path:'/allyoucaneat/:tempId/:customId/:id',
        component:() => import('../views/pages/AllYouCanEat.vue'),
        meta: { title: ''}
    },
    {
        path:'/contact/:tempId/:customId/:id',
        component:() => import('../views/pages/Contact.vue'),
        meta: { title: ''}
    },
    {
        path:'/register/:tempId/:id',
        component:() => import('../views/pages/Register.vue'),
        meta: { title: ''}
    },
    {
        path:'/login/:tempId/:id',
        component:() => import('../views/pages/Login.vue'),
        meta: { title: ''}
    },
    {
        path:'/userCenter/:tempId/:id',
        component:() => import('../views/pages/UserCenter.vue'),
        meta: { title: ''}
    },
    //mobile
    {
        path:'/mobile/takeaway/:id',
        component:() => import('../views/mobile/IndexView.vue'),
        meta: { title: ''},
    },
    {
        name:'mobileFoodList',
        path:'/mobile/takeaway/foodlist/:id',
        component:() => import('../views/mobile/FoodListView.vue'),
        meta: { title: ''}
    },
   
    {
        path:'/mobile/takeaway/cartlist/:id',
        component:() => import('../views/mobile/CartListView.vue'),
        meta: { title: ''}
    },
    {
        path:'/mobile/takeaway/time/:id',
        component:() => import('../views/mobile/TimeView.vue'),
        meta: { title: ''}
    },
    {
        path:'/mobile/takeaway/buyerInfo/:id',
        component:() => import('../views/mobile/BuyerInfoView.vue'),
        meta: { title: ''}
    },
    {
        path:'/mobile/takeaway/success/:id',
        component:() => import('../views/mobile/OrderSuccessView.vue'),
        meta: { title: ''}
    },
    {
        path:'/mobile/takeaway/detail/:id',
        component:() => import('../views/mobile/OrderDetailsView.vue'),
        meta: { title: ''}
    },
    {
        path:'/mobile/booking/:tempId/:id',
        component:() => import('../views/mobile/BookingTable.vue'),
        meta: { title: ''}
    },
    {
        path:'/mobile/allyoucaneat/:tempId/:customId/:id',
        component:() => import('../views/mobile/AllYouCanEat.vue'),
        meta: { title: ''}
    },
    {
        path:'/mobile/contact/:tempId/:customId/:id',
        component:() => import('../views/mobile/Contact.vue'),
        meta: { title: ''}
    },
    // {
    //     path:'*',
    //     component:() => import('../views/staticRoutes/static1.vue'),
    //     meta: { title: '错误404',hidden:true}
    // }
      //demo 
      {
        path:'/mobile/takeaway/:id/:foodTypeId',
        component:() => import('../views/mobile/IndexView.vue'),
        meta: { title: ''}
    },
    {
        path:'/mobile/takeaway/foodlist/:id/:foodTypeId/:foodTypeName/:showType',
        component:() => import('../views/mobile/FoodListView.vue'),
        meta: { title: ''}
    },
    {
        path:'/mobile/takeaway/time/:id/:deliveryTip',
        component:() => import('../views/mobile/TimeView.vue'),
        meta: { title: ''}
    },
    {
        path:'/mobile/takeaway/buyerInfo/:id/:deliveryTip',
        component:() => import('../views/mobile/BuyerInfoView.vue'),
        meta: { title: ''}
    },
    {
        path:'/mobile/info/:tempId/:customId/:id',
        component:() => import('../views/mobile/CustomView.vue'),
        meta: { title: 'Info'}
    },
    {
        path:'/b/takeaway/:id',
        component:() => import('../views/takeaway02/IndexView.vue'),
        meta: { title: 'Takeaway'}
    },
    {
        path:'/b/checkout/:id',
        component:() => import('../views/takeaway02/Checkout.vue'),
        meta: { title: 'Checkout'}
    },
    {
        path:'/b/order/:id',
        component:() => import('../views/takeaway02/OrderDetial.vue'),
        meta: { title: 'My order'}
    },
    {
        path:'/b/show/:activeIndex/:id',
        component:() => import('../views/takeaway02/Custom.vue'),
        meta: { title: 'Info'}
    },

    {
        path:'/booking/:id',
        component:() => import('../views/booking/Index.vue'),
        meta: { title: 'Booking'}
    },
]
